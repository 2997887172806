"use client";

import React, { useCallback, useState } from "react";

import type {
  EventEntity,
  EventEntityBase,
  IngestEventRequest,
} from "@/hl-common/types/api/entities/Events";

import { event_type } from "@/hl-common/types/api/PrismaEnums";
import type { CourseEntityWithUnmetPrerequisites } from "@/hl-common/types/api/entities/Courses";
import type { ModuleEntity } from "@/hl-common/types/api/entities/Modules";
import { getCourseWithStatus, getUserEventsByCourse } from "./api/client";
import { getErrorMessage } from "./api/fetch";

export const CourseContext = React.createContext({
  course: null as CourseEntityWithUnmetPrerequisites | null,
  courseError: "",
  courseLoading: true,
  courseCompleteEvent: null as EventEntity | null,
  loadCourse: async (courseId: number) => {},

  courseEvents: [] as EventEntityBase[],
  courseEventsError: "",
  courseEventsLoading: false,
  loadCourseEvents: async (courseId: number) => {},
  addCourseEvent: (event: IngestEventRequest) => {},
  hasLocalEvents: false,

  moduleIndex: (moduleId: number) => 0 as number,
  moduleStatus: (module: Partial<ModuleEntity>, events: EventEntityBase[]) =>
    ModuleStatus.Locked as ModuleStatus,
  recommendedNextModule: null as Partial<ModuleEntity> | null,
  getFirstAvailableModule: (events: EventEntityBase[]) =>
    null as Partial<ModuleEntity> | null,
  isCourseComplete: (moduleId: number) => false as boolean,

  // user intents
  userIntentCourseId: null as number | null,
  setUserIntentCourseId: (courseId: number | null) => {},

  userIntentModuleId: null as number | null,
  setUserIntentModuleId: (courseId: number | null) => {},
});

export const WithCourse = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [course, setCourse] = useState(
    null as CourseEntityWithUnmetPrerequisites | null,
  );
  const [courseCompleteEvent, setCourseCompleteEvent] = useState(
    null as EventEntity | null,
  );

  // courseEvents is a mix of local and server-synced events, so we can't count on them having an ID
  const [courseEvents, setCourseEvents] = useState<EventEntityBase[]>([]);
  const [courseEventsError, setCourseEventsError] = useState("");
  const [courseEventsLoading, setCourseEventsLoading] = useState(false);

  // occasionally we want to re-sync client with server, and this bool helps us detect if that's worth doing
  const [hasLocalEvents, setHasLocalEvents] = useState(false);

  // we use userIntentCourseId to short-circuit the suggested next course for a user...
  // normally we use a course's priority to sort (and suggest a next course for a user)
  // this supercedes the default "next" course in some situations, and can also be used
  // for other purposes
  // e.g. to highlight the course on the /courses page
  const [userIntentCourseId, setUserIntentCourseId] = useState<number | null>(
    null,
  );

  // similar to the above, but just for modules
  const [userIntentModuleId, setUserIntentModuleId] = useState<number | null>(
    null,
  );

  // use for transitions at the end of a module
  const [recommendedNextModule, setRecommendedNextModule] =
    useState<Partial<ModuleEntity> | null>(null);

  // called from the layout!
  const loadCourse = useCallback(
    async (courseId: number) => {
      setError("");
      setLoading(true);

      if (courseId !== course?.id) {
        setCourse(null);
      }

      try {
        const resp = await getCourseWithStatus({ params: { courseId } });
        setCourse(resp.data);
      } catch (error) {
        setError(getErrorMessage(error));
      }

      setLoading(false);
    },
    [course],
  );

  // get "module_begin", "module_complete", and "course_complete" events for the course
  const loadCourseEvents = useCallback(async (courseId: number) => {
    setCourseEventsError("");
    setCourseEventsLoading(true);
    setCourseCompleteEvent(null);
    setCourseEvents([]);

    try {
      const resp = await getUserEventsByCourse({ params: { courseId } });
      setCourseEvents(resp.data);
      setCourseCompleteEvent(
        resp.data.find((e) => e.type === event_type.course_complete) || null,
      );
      setHasLocalEvents(false);
    } catch (error) {
      setCourseEventsError(getErrorMessage(error));
    }

    setCourseEventsLoading(false);
  }, []);

  const getFirstAvailableModule = useCallback(
    (events: EventEntityBase[]) => {
      return (
        course?.modules?.find((module) => {
          const status = moduleStatus(module, events);
          return isAvailable(status);
        }) ?? null
      );
    },
    [course],
  );

  // we *must* pass the not-yet-synced events (instead of just using courseEvents)
  // for this to reflect the latest info on the very next render loop
  const updateRecommendedNextModule = useCallback(
    (events: EventEntityBase[]) => {
      // see if the 'intent' module is unlocked
      if (userIntentModuleId) {
        const module = course?.modules?.find(
          (m) => m.id === userIntentModuleId,
        );
        if (module) {
          const status = moduleStatus(module, events);
          if (isAvailable(status)) {
            setRecommendedNextModule(module);
            return;
          }
        }
      }

      // otherwise return the first available
      setRecommendedNextModule(getFirstAvailableModule(events));
    },
    [userIntentModuleId, course?.modules, getFirstAvailableModule],
  );

  // stick not-yet-sync'd events into the CourseContext, so we can use them
  // e.g. to calculate the next available module, without another roundtrip to the server
  const addCourseEvent = useCallback(
    (event: IngestEventRequest) => {
      const newEvents = [event];

      // expand extraEvents into their own entries
      // as if they were fetched from the server
      if (event.extraEvents) {
        if (event.extraEvents.moduleBeginUuid) {
          newEvents.push({
            uuid: event.extraEvents.moduleBeginUuid,
            courseId: event.courseId,
            moduleId: event.moduleId,
            type: event_type.module_begin,
            timestamp: event.timestamp,
          });
        }
        if (event.extraEvents.moduleCompleteUuid) {
          newEvents.push({
            uuid: event.extraEvents.moduleCompleteUuid,
            courseId: event.courseId,
            moduleId: event.moduleId,
            type: event_type.module_complete,
            timestamp: event.timestamp,
          });
        }
        if (event.extraEvents.courseCompleteUuid) {
          newEvents.push({
            uuid: event.extraEvents.courseCompleteUuid,
            courseId: event.courseId,
            type: event_type.course_complete,
            timestamp: event.timestamp,
          });
        }
      }

      // merge new events with existing events
      const allEvents = [...courseEvents, ...newEvents];
      setCourseEvents(allEvents);

      // flag that local events are present
      setHasLocalEvents(true);

      // update recommendedNextModule if we just finished a module
      if (event.extraEvents?.moduleCompleteUuid) {
        updateRecommendedNextModule(allEvents);

        // clear userIntentModuleId if we just finished that module
        if (userIntentModuleId === event.moduleId) {
          setUserIntentModuleId(null);
        }
      }

      // clear userIntentCourseId if we just finished that course
      if (
        event.extraEvents?.courseCompleteUuid &&
        userIntentCourseId === event.courseId
      ) {
        setUserIntentCourseId(null);
      }
    },
    [
      courseEvents,
      userIntentModuleId,
      userIntentCourseId,
      updateRecommendedNextModule,
    ],
  );

  // get the index of the module in the course
  const moduleIndex = useCallback(
    (moduleId: number) => {
      return findModuleIndex(course, moduleId);
    },
    [course],
  );

  // note: this takes an events param even though courseEvents exists in this context
  // that's not a bug! we *must* pass the not-yet-synced events for this to work correctly
  const moduleStatus = useCallback(
    (module: Partial<ModuleEntity>, events: EventEntityBase[]) => {
      const moduleId = module.id as number;

      if (course?.unmetPrerequisites?.length) {
        return ModuleStatus.Locked;
      }

      if (isCompleted(events, moduleId)) {
        if (module.repeatable) {
          return ModuleStatus.Repeatable;
        }
        return ModuleStatus.Completed;
      }

      if (isStarted(events, moduleId)) {
        return ModuleStatus.Started;
      }

      return lockedOrUnlocked(course, events, moduleId);
    },
    [course],
  );

  // checks courseEvents to see if the course was *just* completed,
  // by including a bypass for the current module which might not
  // yet have its event in courseEvents
  const isCourseComplete = useCallback(
    (moduleId: number) => {
      if (!course?.modules) {
        return false;
      }

      const previouslyCompletedModules = new Set(
        courseEvents
          .filter((e) => e.type === "module_complete")
          .map((e) => e.moduleId),
      );

      return (
        previouslyCompletedModules.size === course.modules.length - 1 &&
        !previouslyCompletedModules.has(moduleId)
      );
    },
    [courseEvents, course],
  );

  return (
    <CourseContext.Provider
      value={{
        course,
        courseError: error,
        courseLoading: loading,
        courseCompleteEvent,
        loadCourse,

        courseEvents,
        courseEventsError,
        courseEventsLoading,
        loadCourseEvents,
        addCourseEvent,
        hasLocalEvents,

        moduleIndex,
        moduleStatus,
        recommendedNextModule,
        getFirstAvailableModule,
        isCourseComplete,

        userIntentCourseId,
        setUserIntentCourseId,
        userIntentModuleId,
        setUserIntentModuleId,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};

const isCompleted = (courseEvents: EventEntityBase[], moduleId: number) => {
  return courseEvents.find(
    (event) =>
      event.moduleId === moduleId && event.type === event_type.module_complete,
  );
};

const isStarted = (courseEvents: EventEntityBase[], moduleId: number) => {
  return courseEvents.find(
    (event) =>
      event.moduleId === moduleId && event.type === event_type.module_begin,
  );
};

// lockedOrUnlocked returns the status of a module, given the course's modules,
// and any existing module events
// TODO: unit test the heck out of this
const lockedOrUnlocked = (
  course: CourseEntityWithUnmetPrerequisites | null,
  courseEvents: EventEntityBase[],
  moduleId: number,
) => {
  // get the index of the module in the course
  const moduleIdx = findModuleIndex(course, moduleId);

  if (moduleIdx === 0) {
    // first module always open
    return ModuleStatus.Unlocked;
  }

  // Determine the result for each access pattern.
  switch (course?.moduleAccessPattern) {
    case "open access":
      return ModuleStatus.Unlocked;

    case "open access with pre-test and post-test":
      if (course?.modules?.length && moduleIdx === course.modules.length - 1) {
        // last module, check if completed all previous modules
        if (
          course?.modules
            ?.slice(0, -1)
            .every((module) => isCompleted(courseEvents, module.id as number))
        ) {
          return ModuleStatus.Unlocked;
        }
      } else {
        // middle modules, check if first module is completed
        if (isCompleted(courseEvents, course?.modules?.[0].id as number)) {
          return ModuleStatus.Unlocked;
        }
      }
      return ModuleStatus.Locked;

    case "linear progression": {
      // ensure previous module is completed
      const previousModuleID = course?.modules?.[(moduleIdx as number) - 1]
        .id as number;
      if (isCompleted(courseEvents, previousModuleID)) {
        return ModuleStatus.Unlocked;
      }
      return ModuleStatus.Locked;
    }
  }

  // This is unreachable. Each pattern enforces its own rules. There must be no
  // fall-through in the switch statement above.
  throw new Error("Invalid module access pattern?!");
};

const findModuleIndex = (
  course: CourseEntityWithUnmetPrerequisites | null,
  moduleId: number,
) => {
  if (!course || !course.modules) {
    return -1;
  }

  return course.modules.findIndex((module) => module.id === moduleId);
};

export enum ModuleStatus {
  Unlocked = 1,
  Locked = 2,
  Started = 3,
  Completed = 4,
  Repeatable = 5,
  Loading = 6,
}

// helpers for determining accessible modules
const availableStatuses = new Set([
  ModuleStatus.Unlocked,
  ModuleStatus.Started,
]);

const isAvailable = (status: ModuleStatus) => {
  return availableStatuses.has(status);
};
